import React from "react";




export default function footer(){
 return(<div className="footer text-center text-light bg-dark fixed-bottom" style={{padding:0, marginBottom:0}}>
 <p> &copy; arktorius</p>
</div>

 )
}